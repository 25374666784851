import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "source", "destination", "conditionType", "conditionToContainer" ]
  static classes = [ "hidden" ]
  static values = {
    type: String
  }

  copyQuestion(event) {
    event.preventDefault()

    this.destinationTarget.editor.insertHTML(this.sourceTarget.value)
  }

  conditionTypeTargetConnected() {
    this.setConditionTypeClass()
  }

  changedConditionType() {
    this.setConditionTypeClass()
  }

  setConditionTypeClass() {
    if (this.conditionTypeTarget.value === 'between') {
      this.conditionToContainerTarget.classList.remove(this.hiddenClass)
    } else {
      this.conditionToContainerTarget.classList.add(this.hiddenClass)
    }
  }
}
