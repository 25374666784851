import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'

export default class extends Controller {
  connect() {
    new SlimSelect({
      select: this.element,
      placeholder: 'Select member...',
      closeOnSelect: false,
      hideSelectedOption: true
    })
  }
}
